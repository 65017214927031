define('editAddress', ['captureplus'], function() {
  
  var fields = [
    {
      element: 'addressSearch',
      field: ''
    },
    {
      element: 'companyName',
      field: 'Company',
      mode: window.pca.fieldMode.DEFAULT
    },
    {
      element: 'houseNumber',
      field: '{BuildingNumber}{, {BuildingName}}',
      mode: window.pca.fieldMode.POPULATE
    },
    {
      element: 'streetName',
      field: 'Street',
      mode: window.pca.fieldMode.POPULATE
    },
    {
      element: 'addressLine2',
      field: 'Line2',
      mode: window.pca.fieldMode.POPULATE
    },
    {
      element: 'addressLine3',
      field: 'City',
      mode: window.pca.fieldMode.POPULATE
    },
    {
      element: 'addressLine4',
      field: 'Province',
      mode: window.pca.fieldMode.POPULATE
    },
    {
      element: 'postCode',
      field: 'PostalCode',
      mode: window.pca.fieldMode.DEFAULT
    },
    {
      element: 'country',
      field: 'CountryName',
      mode: window.pca.fieldMode.COUNTRY
    }
  ];

  var options = {
    key: 'AH71-DA52-HB93-WA19',
    bar: {
      visible: false
    }
  };

  function countyToStateSwitch() {
    var country = document.getElementById('country');
    if (!country) {
      return;
    }

    function isUSAaDeliveryOption() {
      for (var i = 0; i < country.options.length; i++) {
        if (country.options[i].value === 'US') {
          return true;
        }
      }
      return false;
    }

    // First check whether the US are a delivery option; if not, there is no need to proceed
    if (!isUSAaDeliveryOption()) {
      return;
    }

    var usStates = [
      'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA',
      'KS', 'KY',
      'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY',
      'NC', 'ND',
      'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
    ];

    var cachedStatesSelectNode;
    var cachedCountyTextInputNode;

    var addressLine4InputField = document.getElementById('addressLine4');
    var addressLine4Row = addressLine4InputField.parentNode;
    var addressLine4Label = addressLine4InputField.previousElementSibling;

    var countyTranslation = addressLine4Row.getAttribute('data-county-translation');
    var stateTranslation = addressLine4Row.getAttribute('data-state-translation') + '*';

    // Cache the address line 4 DOM node in order to avoid building it later in case the user
    // makes several switches
    var countryCode = country.options[country.selectedIndex].value;
    if (!countryCode) {
      return;
    }

    if (countryCode === 'US') {
      cachedStatesSelectNode = addressLine4InputField;
    } else {
      cachedCountyTextInputNode = addressLine4InputField;
    }

    // Sets the attributes present for addressLine4 in edit-address.jsp
    function setCountyOrStateInputFieldAttributes(node) {
      node.setAttribute('id', 'addressLine4');
      node.setAttribute('name', 'addressLine4');
      node.setAttribute('class', 'editAddressCard_formField');
    }

    function getStatesDropdown() {
      if (cachedStatesSelectNode) {
        return cachedStatesSelectNode;
      }

      var stateSelectTag = document.createElement('select');
      setCountyOrStateInputFieldAttributes(stateSelectTag);
      var statesString = '<option value selected="selected"></option>';
      var statesStringOptions = usStates.reduce(function(accum, state) {
        return accum + '<option value="' + state + '">' + state + '</option>';
      }, '');
      stateSelectTag.innerHTML = statesString + statesStringOptions;
      cachedStatesSelectNode = stateSelectTag;
      return stateSelectTag;
    }

    function getCountyInputField() {
      if (cachedCountyTextInputNode) {
        return cachedCountyTextInputNode;
      }

      var countyInputField = document.createElement('input');
      setCountyOrStateInputFieldAttributes(countyInputField);
      cachedCountyTextInputNode = countyInputField;
      return countyInputField;
    }

    country.addEventListener('change', function() {
      var countryCode = country.options[country.selectedIndex].value;
      if (!countryCode) return;

      if (countryCode === 'US') {
        addressLine4Label.innerHTML = stateTranslation;
        var statesDropdown = getStatesDropdown();
        addressLine4Row.replaceChild(statesDropdown, addressLine4InputField);
        addressLine4InputField = statesDropdown;
      } else {
        addressLine4Label.innerHTML = countyTranslation;
        var countyInputField = getCountyInputField();
        addressLine4Row.replaceChild(countyInputField, addressLine4InputField);
        addressLine4InputField = countyInputField;
      }
    });
  }

  countyToStateSwitch();

  return new window.pca.Address(fields, options);
});
